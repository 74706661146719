import anime from 'animejs/lib/anime.es.js';
function randomMove(target, x1, x2, y1, y2, durationTime) {
  anime({
    targets: target,
    rotate: [20, -10],
    direction: 'alternate',
    translateX: function translateX() {
      return anime.random(x1, x2);
    },
    translateY: function translateY() {
      return anime.random(y1, y2);
    },
    easing: 'easeInOutSine',
    duration: durationTime,
    delay: 700,
    complete: function complete() {
      randomMove(target, x1, x2, y1, y2, durationTime);
    }
  });
}
randomMove('.partners-promo__big-star', -10, 10, -10, 10, 4500);
randomMove('.partners-promo__sale', -10, 10, -10, 10, 3500);
randomMove('.partners-promo__bonus', -10, 10, -10, 10, 2700);
randomMove('.partners-promo__green-gift', -20, 20, -20, 20, 4000);
randomMove('.partners-promo__bag', -10, 10, -10, 10, 3500);
randomMove('.partners-promo__pink-gift', -20, 20, -20, 20, 3200);
randomMove('.partners-promo__white-gift', -10, 10, -10, 10, 4200);
randomMove('.partners-promo__shopping-tag', -5, 5, -5, 5, 2500);