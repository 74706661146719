document.addEventListener('DOMContentLoaded', function () {
  var menuBtn = document.querySelector('.header__menu-btn');
  var menu = document.querySelector('.header__mobile-menu');
  var linksBlock = document.querySelector('.header__mobile-menu-links-block');
  var headerLogo = document.querySelector('.header__logo');
  menuBtn.addEventListener('click', function () {
    menuBtn.classList.toggle('active');
    menu.classList.toggle('active');
    linksBlock.classList.toggle('active');
    headerLogo.classList.toggle('active');
    var body = document.body;
    var scrollY;
    if (menuBtn.classList.contains('active')) {
      scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
      body.style.position = 'fixed';
      body.style.top = "-".concat(scrollY);
    } else {
      scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo({
        top: parseInt(scrollY) * -1,
        behavior: 'instant'
      });
    }
  });
  window.addEventListener('scroll', function () {
    document.documentElement.style.setProperty('--scroll-y', "".concat(window.scrollY, "px"));
  });
});