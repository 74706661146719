document.addEventListener('DOMContentLoaded', function () {
  var menuBtn = document.querySelector('.header__menu-btn');
  var menu = document.querySelector('.header__mobile-menu');
  var deactivateMenuLink = document.querySelector('.header__mobile-menu-link_deactivate-menu');
  deactivateMenuLink.addEventListener('click', function () {
    menuBtn.classList.remove('active');
    menu.classList.remove('active');
    var body = document.body;
    var scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  });
});
jQuery(document).ready(function ($) {
  jQuery('a[href^="#"]').bind('click.smoothscroll', function (e) {
    e.preventDefault();
    var target = this.hash,
      $target = jQuery(target);
    jQuery('html, body').stop().animate({
      'scrollTop': $target.offset().top
    }, 900, 'swing', function () {
      window.location.hash = target;
    });
  });
});